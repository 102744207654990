<template>
  <div id="inicio">
    <vue-headful :title="title" />
    <dominio-resumen-component class="top" action="action" />
    <!-- <whois class="top" action="action" /> -->
    <bienvenido-nic class="img-top" />
    <v-img class="" dark height="600" src="../assets/img/datacenter.jpg">
      <v-container> </v-container>
      <v-slide-x-transition appear>
        <v-row justify="center">
          <v-col class="text-center" cols="12">
            <h1
              id="h1paralax"
              class="display-1 font-weight-thin mb-4"
              v-for="(item, i) in items"
              :key="i"
              text
            >
              {{ item.text }}
            </h1>
          </v-col>
        </v-row>
      </v-slide-x-transition>
      <v-row no-gutters justify="center">
        <v-col sm="6" md="5" offset-md="2" lg="6" offset-lg="0">
          <div id="parallax-front">
            <v-list
              color="transparent"
              v-for="(requisito, i) in requisitos"
              :key="'J' + i"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green" v-text="requisito.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-action-text
                    class="ventaja"
                    v-text="requisito.text"
                  ></v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </v-img>
    <!-- <section class="noticia">
    <noticia-nic />
  </section>   -->
    <banner-cisco class="cisco" />
    <card-phone class="phone" />
    <parnerts />
  </div>
</template>
<script>
import vueHeadful from "vue-headful";

/*
let tempDomain = localStorage.getItem('tempDomain');
let tempZone = localStorage.getItem('tempZone');
let tempZoneId = localStorage.getItem('tempZoneId');
if(tempDomain){
  this.dominioModel.dominio = tempDomain;
  this.$store.commit('searchZone', tempZone);
  this.$store.commit('searchZoneId', tempZoneId);
  console.log('tempDomain : ',tempDomain, ' ',tempZone, ' ',tempZoneId);
  await this.availabledomains();
}
*/

export default {
  components: {
    vueHeadful,
    BannerCisco: () => import("@/components/BannerCisco"),
    CardPhone: () => import("@/components/CardPhone"),
    Parnerts: () => import("@/components/Parnerts"),
    BienvenidoNic: () => import("@/components/BienvenidoNic"),
    // NoticiaNic: () => import ('@/components/NoticiaNic'),
    DominioResumenComponent: () =>
      import("@/components/DominioResumenComponent"),
    //  Whois: () => import("@/components/Whois"),
  },

  data: () => ({
    title: "Inicio",
    action: "route",
    items: [
      {
        text: " VENTAJAS DE  TENER UN DOMINIO .NI",
      },
    ],
    requisitos: [
      {
        text:
          "Proteccíon de tu nombre personal o el de tu institución y obtienes un nombre fácil de recordar para utilizarlo en Internet.",
        icon: "mdi-checkbox-marked-circle",
      },
      {
        text:
          "Tu nombre o el de tu institución se geo posicionan en internet con Nicaragua.",
        icon: "mdi-checkbox-marked-circle",
      },
      {
        text:
          " Tu sitio web es visible a nivel mundial www.nombredemiempresa.com.ni",
        icon: "mdi-checkbox-marked-circle",
      },
      {
        text:
          "Tus correos electrónicos serán institucionalizados o personalizados.",
        icon: "mdi-checkbox-marked-circle",
      },
    ],
  }),
};
</script>
<style scoped>
.ventaja{
  font-size: 20px;
}
.top {
  margin-top: -0px !important;
}
@media (max-width: 725px) {
  .top {
    margin-top: -120px !important;
  }
}
#h1paralax {
  margin-top: 40px !important;
  font-weight: 600 !important;
}
.v-main .img-top {
  margin-top: 40px;
}
#parallax-front {
  margin-top: 60px;
}
.parallax-front {
  font-size: 16px !important;
}
.v-application p {
  padding: 10px;
}
@media (max-width: 725px) {
  #paralax-front {
    margin-top: 30px;
    font-size: 16px;
    padding: 10px;
  }
  #parallax-front {
    margin-top: -20px;
  }
  .h1paralax {
    margin-top: 10px;
  }

  .cisco {
    display: none !important;
  }
}
@media (min-width: 725px) {
  .phone {
    display: none !important;
  }
  .parallax-front {
    font-size: 14px !important;
  }
}
</style>
